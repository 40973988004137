<script setup lang="ts">
import { TableCellProps } from '.'
import { computed, defineProps, useCssModule, withDefaults } from 'vue'

type Props = {
  colspan?: TableCellProps['colspan']
  separatorable?: TableCellProps['separatorable']
  marginRight?: TableCellProps['marginRight']
}
const props = withDefaults(defineProps<Props>(), {
  colspan: 1,
  separatorable: true,
  marginRight: 0,
})
const withMargin = computed(() => props.marginRight > 0)
const marginRightPx = computed(() => `${props.marginRight * 4}px`)

const cssModule = useCssModule()
const classes = computed(() => [
  cssModule[!withMargin.value ? 'table-cell' : 'table-cell-with-margin'],
  {
    [cssModule.separator]: props.separatorable,
  },
])
</script>

<style lang="scss" module>
@use '@/styles/colors' as colors;
@use '@/styles/functions/space' as *;
.table-cell {
  border-bottom: 1px solid colors.$table-border-bottom-color;
  box-sizing: border-box;
  padding: space(2);
  background-color: inherit;
  color: inherit;
  vertical-align: middle;
}
.table-cell-wrapper {
  vertical-align: bottom;
}
.table-cell-inner {
  height: 100%;
}
.table-cell-with-margin {
  @extend .table-cell;
  margin-right: v-bind('marginRightPx');
}
.separator {
  border-right: 1px dotted colors.$table-border-right-color;
}
</style>

<template>
  <td
    :class="!withMargin ? classes : $style['table-cell-wrapper']"
    :colspan="props.colspan"
  >
    <div :class="[$style['table-cell-inner'], ...(withMargin ? classes : [])]">
      <slot></slot>
    </div>
  </td>
</template>
