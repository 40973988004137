import { ValueOf } from '@/types/utils'
import {
  EVENT_STATUS,
  DISPATCH_TYPE,
} from '@/utils/constants/messages/photographer/event/index'
import {
  EventStatusCode,
  EventTypeCode,
} from '@/utils/constants/enums/photographer/event/index'
import { REQUESTER_EVENT_STATUS } from '@/utils/constants/messages/requester/event'
import { RequesterEventStatusCode } from '@/utils/constants/enums/requester/event'

type EventStatus = ValueOf<typeof EVENT_STATUS>
type DispatchType = ValueOf<typeof DISPATCH_TYPE>
type RequesterEventStatus = ValueOf<typeof REQUESTER_EVENT_STATUS>

export const convertEventStatus = (code: number): EventStatus | '' => {
  switch (code) {
    case EventStatusCode.ORDERED:
      return EVENT_STATUS.ORDERED
    case EventStatusCode.WAITING_EVENT:
      return EVENT_STATUS.WAITING_EVENT
    case EventStatusCode.BEFORE_DELIVERY:
      return EVENT_STATUS.BEFORE_DELIVERY
    case EventStatusCode.CANCELLED:
      return EVENT_STATUS.CANCELLED
    case EventStatusCode.WAITING_INSPECTION:
      return EVENT_STATUS.WAITING_INSPECTION
    case EventStatusCode.INSPECTING:
      return EVENT_STATUS.INSPECTING
    case EventStatusCode.SENDING_BACK:
      return EVENT_STATUS.SENDING_BACK
    case EventStatusCode.DELIVERED:
      return EVENT_STATUS.DELIVERED
    case EventStatusCode.BEFORE_ANSWER:
      return EVENT_STATUS.BEFORE_ANSWER
    default:
      return ''
  }
}
export const convertDispatchType = (code: number): DispatchType => {
  switch (code) {
    case EventTypeCode.AS_PLANNED:
      return DISPATCH_TYPE.AS_PLANNED
    case EventTypeCode.POSTPONED:
      return DISPATCH_TYPE.POSTPONED
    default:
      return DISPATCH_TYPE.AS_PLANNED
  }
}

/**
 * 提携先依頼元イベントステータスコードを変換する
 * @param code
 * @returns 提携先依頼元イベントステータス
 */
export const convertRequesterEventStatus = (
  code: number
): RequesterEventStatus | '' => {
  switch (code) {
    case RequesterEventStatusCode.WAITING_EVENT:
      return REQUESTER_EVENT_STATUS.WAITING_EVENT
    case RequesterEventStatusCode.CLOSED:
      return REQUESTER_EVENT_STATUS.CLOSED
    case RequesterEventStatusCode.DELIVERED:
      return REQUESTER_EVENT_STATUS.DELIVERED
    case RequesterEventStatusCode.CANCELLED:
      return REQUESTER_EVENT_STATUS.CANCELLED
    default:
      return ''
  }
}
