export const ERRORMSG = {
  MAIL_AND_PASSWORD_MISMATCH: 'メールアドレスまたはパスワードが違います。',
  MAIL_EMPTY: 'メールアドレスは必ず入力する必要があります。',
  MAIL_PATTERN_MISMATCH: 'メールアドレスの形式が正しくありません。',
  MAIL_TOO_LONG: 'メールアドレスは254文字以下で入力してください。',
  PASSWORD_EMPTY: 'パスワードは必ず入力する必要があります。',
  PASSWORD_PATTERN_MISMATCH: 'パスワードの形式が正しくありません。',
  PASSWORD_TOO_SHORT: 'パスワードは8文字以上で入力してください。',
  PASSWORD_TOO_LONG: 'パスワードは20文字以下で入力してください。',
  PHOTOGRAPHER_LOGIN_PASSWORD_TOO_LONG:
    'ログインIDまたはパスワードが違います。',
  REQUESTER_LOGIN_PASSWORD_TOO_LONG:
    'メールアドレスまたはパスワードが違います。',
  PASSWORD_COMFIRMPASSWORD_MISMATCH:
    'パスワードと再入力パスワードが一致しません。',
  LOGINID_EMPTY: 'ログインIDは必ず入力する必要があります。',
  LOGINID_PATTERN_MISMATCH: 'ログインIDの形式が正しくありません。',
  LOGINID_TOO_LONG: '50文字以下で入力してください。',
  BROKEN_IMAGE: '画像が破損しています\n上書きまたは削除を\nして下さい',
  IMAGE_RESOLUTION:
    '最低画素数に\n満たない写真です\n上書きまたは削除を\nして下さい',
  UPLOAD_FAILED:
    'アップロード処理に\n失敗しました\n上書きまたは削除を\nして下さい',
  EMPTY: '',
  NOT_ENTERED: '未入力です',
} as const
