import { computed, onMounted, reactive, ref, Ref } from 'vue'

export type TableProps = {
  scrollable?: {
    bodyHeight: number
    gridTemplateColumns: string[]
  }
}

type TableStates = {
  table: HTMLElement | null
  tableBody: HTMLElement | null
  scrollbarWidth: string
  readonly isScrollable: boolean
  readonly tableBodyMaxHeight: CSSStyleDeclaration['maxHeight']
  readonly gridTemplateColumns: CSSStyleDeclaration['gridTemplateColumns']
  readonly isOverflown: boolean
  readonly overflownGridTemplateColumns: CSSStyleDeclaration['gridTemplateColumns']
}

const createIsOverFlownComputer =
  (props: TableProps, tableBody: Ref<TableStates['tableBody']>) =>
  (): TableStates['isOverflown'] => {
    const { scrollable } = props
    const tbodyElement = tableBody.value
    if (!scrollable || !tbodyElement) return false
    return tbodyElement.clientHeight >= scrollable.bodyHeight
  }

const createOverflownGridTemplateColumnsComputer =
  (props: TableProps, scrollbarWidth: Ref<TableStates['scrollbarWidth']>) =>
  (): TableStates['overflownGridTemplateColumns'] => {
    const { scrollable } = props
    if (!scrollable) return 'auto'
    return scrollable.gridTemplateColumns
      .map((col, i, arr) => {
        if (i < arr.length - 1) return col
        return `calc(${col} + ${scrollbarWidth.value})`
      })
      .join(' ')
  }

export const useTableStates = (props: TableProps): TableStates => {
  const tableBody = ref<TableStates['tableBody']>(null)
  const scrollbarWidth = ref<TableStates['scrollbarWidth']>('0')
  return reactive({
    table: null,
    tableBody,
    scrollbarWidth,
    isScrollable: computed(() => !!props.scrollable),
    tableBodyMaxHeight: computed(() =>
      props.scrollable ? `${props.scrollable.bodyHeight}px` : 'auto'
    ),
    gridTemplateColumns: computed(
      () => props.scrollable?.gridTemplateColumns.join(' ') ?? 'none'
    ),
    isOverflown: computed(createIsOverFlownComputer(props, tableBody)),
    overflownGridTemplateColumns: computed(
      createOverflownGridTemplateColumnsComputer(props, scrollbarWidth)
    ),
  })
}

export const useTableActions = (states: TableStates) => {
  onMounted(() => {
    const { table, tableBody } = states
    if (!table || !tableBody) return
    states.scrollbarWidth = `${table.clientWidth - tableBody.clientWidth}px`
  })
}
