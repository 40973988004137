<script setup lang="ts">
import { defineEmits, defineProps, withDefaults } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Checkbox from '@/vueComponents/photographer/molecules/Checkbox/index.vue'
import {
  CheckboxWithLabelEmitParams,
  CheckboxWithLabelProps,
  useCheckboxWithLabelActions,
  useCheckboxWithLabelStates,
} from '.'

interface IProps {
  checked: CheckboxWithLabelProps['checked']
  type?: CheckboxWithLabelProps['type']
  disabled?: CheckboxWithLabelProps['disabled']
  hideCheckbox?: CheckboxWithLabelProps['hideCheckbox']
}

interface IEmits {
  (
    e: 'update:checked',
    value: CheckboxWithLabelEmitParams['update:checked']
  ): void
}

const props = withDefaults(defineProps<IProps>(), {
  type: 'normal',
  disabled: false,
  hideCheckbox: false,
})

const emits = defineEmits<IEmits>()

const states = useCheckboxWithLabelStates(props, emits)
const actions = useCheckboxWithLabelActions(states, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
.container {
  display: flex;
  gap: space(2);
  align-items: center;
}
.checkbox {
  flex-shrink: 16px;
}
.text {
  cursor: pointer;
  overflow-wrap: anywhere;
}
</style>

<template>
  <div :class="css.container">
    <div v-if="!props.hideCheckbox" :class="css.checkbox">
      <Checkbox v-model:checked="states.checked" :disabled="props.disabled" />
    </div>
    <div :class="css.text" @click="actions.onClick">
      <Typography :type="states.textType"><slot /></Typography>
    </div>
  </div>
</template>
