<script setup lang="ts">
import { defineProps, withDefaults } from 'vue'
import { globalConstants } from '@/utils/constants/globalConstants'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import BeforeLoginContentBox from '@/vueComponents/photographer/organisms/contentBox/BeforeLoginContentBox/index.vue'
import { ErrorPageContentProps } from '.'

interface IProps {
  withContacts?: ErrorPageContentProps['withContacts']
}

const props = withDefaults(defineProps<IProps>(), {
  withContacts: false,
})

const {
  COMPANY_INFO: { TEL, EMAIL },
} = globalConstants
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/sizes' as sizes;
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/space' as space-mixins;
.container {
  > p {
    text-align: center;
  }
}
.main-message {
  white-space: pre-line;
  font-size: sizes.$font-size-xl;
}
.mail-container {
  @include space-mixins.vertical-margin(8);
}
.tel-container {
  @include space-mixins.vertical-margin(4);
}
.link {
  &-container {
    font-size: sizes.$font-size-xl;
  }

  &-anchor {
    text-decoration: none;
    line-height: 24px;
  }

  &-text {
    color: colors.$font-color-primary;
    font-weight: bold;
    margin-left: space(1);
  }
}
</style>

<template>
  <BeforeLoginContentBox>
    <div :class="css.container">
      <p :class="css['main-message']"><slot /></p>
      <template v-if="props.withContacts">
        <p :class="[css['link-container'], css['mail-container']]">
          メールでのお問い合わせはこちらから<br />
          <a :href="`mailto:${EMAIL}`" :class="css['link-anchor']"
            ><Icon type="error-page-email" /><span :class="css['link-text']">{{
              EMAIL
            }}</span></a
          >
        </p>
        <p :class="[css['link-container'], css['tel-container']]">
          電話でのお問い合わせはこちらから<br />
          <a :href="`tel:${TEL}`" :class="css['link-anchor']"
            ><Icon type="error-page-phone" /><span :class="css['link-text']">{{
              TEL
            }}</span></a
          ><br />
          お問い合わせ受付時間：平日10:00～18:00(土日祝・盆時期・年末年始除く)
        </p>
      </template>
    </div>
  </BeforeLoginContentBox>
</template>
