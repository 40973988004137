<script setup lang="ts">
import { useLoginHeaderStates } from '.'

const states = useLoginHeaderStates()
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/img' as img-mixins;
@use '@/styles/mixins/media' as media-mixins;
@use '@/styles/mixins/position' as position-mixins;
.container {
  @include position-mixins.flex-center;
  background-position: center;
  background-size: cover;
  height: 390px;
  background-image: v-bind('states.backgroundImgUrlPc');
}
.logo {
  max-width: 360px;
  max-height: 100%;
}
</style>

<template>
  <div :class="css.container">
    <img :class="css.logo" :src="states.logoImgUrl" />
  </div>
</template>
