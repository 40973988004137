<script setup lang="ts">
import { defineProps, withDefaults, defineEmits, ref } from 'vue'
import { IInputProps } from '.'

interface IProps {
  name?: IInputProps['name']
  value?: IInputProps['value']
  type: IInputProps['type']
  placeholder?: IInputProps['placeholder']
  disabled?: IInputProps['disabled']
  hasError?: IInputProps['hasError']
  isShadow?: IInputProps['isShadow']
  width?: IInputProps['width']
}

interface IInputEmits {
  (e: 'update:value', value: IProps['value']): void
}

const props = withDefaults(defineProps<IProps>(), {
  name: '',
  value: undefined,
  placeholder: '',
  disabled: false,
  hasError: false,
  isShadow: false,
  width: '100%',
})

const input = ref<HTMLInputElement>()

const emits = defineEmits<IInputEmits>()

const handleInput = () => {
  emits('update:value', input.value?.value)
}
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/input' as input;
@use '@/styles/colors' as colors;

.shadow-input {
  @extend %shadow-input;
}
.input {
  @extend %input;
}
.error {
  border-radius: 2px;
  border: 1px solid colors.$border-color-attention;
  background: colors.$background-color-danger;
}
</style>

<template>
  <input
    ref="input"
    :class="[
      props.isShadow ? css['shadow-input'] : css.input,
      props.hasError ? css['error'] : '',
    ]"
    :name="props.name"
    :value="props.value"
    :type="props.type"
    :placeholder="props.placeholder"
    :disabled="props.disabled"
    :style="{ width: props.width }"
    @input.self="handleInput"
  />
</template>
