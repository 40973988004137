import { inject, InjectionKey } from 'vue'
import { BaseStore } from '@/vue/stores'

/** エンティティの基幹クラス */
export class BaseEntity<Store extends BaseStore> {
  protected readonly store!: Store

  constructor(injectionKey: InjectionKey<Store>) {
    const injectedStore = inject(injectionKey)
    if (!injectedStore) {
      throw new Error(
        `${injectionKey.toString()}に該当するストアが提供されていません`
      )
    }
    this.store = injectedStore
  }
}
