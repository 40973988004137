import { MESSAGES } from '@/utils/constants/messages'
import {
  createRouter,
  createWebHistory,
  Router,
  RouteRecordRaw,
} from 'vue-router'

/**
 * タイトルタグ設定
 */
const updateMetaTitle = (pathTitle: string): void => {
  const siteTitle = MESSAGES.PHOTOGRAPHER.SERVICE_NAME
  const pageTitle = pathTitle ? pathTitle + ' | ' + siteTitle : siteTitle
  window.document.title = pageTitle
}

export const initRouter = (routes: RouteRecordRaw[]): Router => {
  const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth',
        }
      }
      return to.name === from.name && savedPosition ? savedPosition : { top: 0 }
    },
    routes,
  })
  router.afterEach((to) => {
    updateMetaTitle((to.meta.title as string) ?? '')
  })

  return router
}
