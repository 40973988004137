import { Emits } from '@/types/utils'

export type CheckboxProps = {
  checked: boolean
  disabled?: boolean
}

export type CheckboxEmitParams = {
  'update:checked': boolean
}

type CheckboxActions = {
  handleOnClick: () => void
}

export const useCheckboxActions = (
  props: CheckboxProps,
  emits: Emits<CheckboxEmitParams>
): CheckboxActions => ({
  handleOnClick() {
    if (props.disabled) return
    emits('update:checked', !props.checked)
  },
})
