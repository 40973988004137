<script setup lang="ts">
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import { useAccordionStates, useAccordionActions } from '.'

const states = useAccordionStates()
const actions = useAccordionActions(states)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/mixins/position' as position-mixin;
@use '@/styles/functions/space' as *;
.container {
  background-color: colors.$background-color-content-primary;
}
.title {
  @include position-mixin.flex-horizontal();
  justify-content: space-between;
  cursor: pointer;
}
.icon {
  margin-right: space(3.5);
}
.content {
  max-height: 9999px;
}
</style>

<style lang="scss" scoped>
@use '@/styles/mixins/animation' as animation-mixins;
.toggle {
  @include animation-mixins.vertical-expansion;
}
</style>

<template>
  <div :class="css.container">
    <div :class="css.title" @click="actions.toggle">
      <slot name="title" />
      <div :class="css.icon"><Icon :type="states.iconType" /></div>
    </div>
    <Transition name="toggle">
      <div v-show="states.isOpened" :class="css.content">
        <slot />
      </div>
    </Transition>
  </div>
</template>
