import { Component, createApp } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { AxiosPlugin } from '@/vue/plugins/AxiosPlugin'
import { BugsnagPlugin } from '@/vue/plugins/BugsnagPlugin'
import { Router } from 'vue-router'
import { Stores } from '@/vue/stores'
import { loadFontFamily, setFavicon } from './init'
import '@/styles/css_init.scss'

export const mountVue = (
  mountId: string,
  rootComponent: Component,
  router?: Router,
  stores?: Stores
): void => {
  loadFontFamily().catch(() => {
    // フォント読み込み失敗時には何もしない
  })
  setFavicon()
  const app = createApp(rootComponent)
  app.use(BugsnagPlugin)
  app.use(AxiosPlugin)
  if (router) app.use(router)
  if (stores) app.use(stores)
  app.component('font-awesome-icon', FontAwesomeIcon)
  app.mount(mountId)
}
