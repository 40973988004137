<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import {
  HeaderButtonProps,
  useHeaderButtonStates,
  useHeaderButtonActions,
} from '.'

interface IProps {
  buttonType: HeaderButtonProps['buttonType']
}

interface IEmits {
  (e: 'click'): void
}

const props = defineProps<IProps>()

const emits = defineEmits<IEmits>()

const states = useHeaderButtonStates(props)

const actions = useHeaderButtonActions(states)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;
@use '@/styles/mixins/media' as media-mixins;
.wrap {
  display: inline-block;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 73px;
  padding: space(5) space(3);
  color: colors.$font-color-white;
  @include media-mixins.media-sp {
    padding: space(1) space(3);
  }
}
.name {
  @include space-mixins.vertical-margin(1);
}
.icon {
  height: 20px;
}
</style>

<template>
  <div :class="css.wrap">
    <Link
      display="block"
      :on-click="() => emits('click')"
      :hover-type="states.hoverType"
    >
      <div
        :class="css.container"
        @mouseover="actions.hoverLink"
        @mouseleave="actions.leaveLink"
      >
        <Icon
          :class="css.icon"
          :type="props.buttonType"
          :is-block="true"
          :hover-type="states.isHover ? states.hoverType : undefined"
        />
        <Typography
          type="read-small-white"
          :class="css.name"
          :hover-type="states.isHover ? states.hoverType : undefined"
          >{{ states.buttonLabel }}</Typography
        >
      </div>
    </Link>
  </div>
</template>
