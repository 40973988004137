export const enum PhotoUploadStatus {
  /** S3へアップロード中 */
  WAITING_UPLOAD_S3 = 10,
  /** 画像チェック中 */
  VALIDATING = 20,
  /** 画像トリミング中 */
  TRIMMING = 30,
  /** サムネイル作成中 */
  CREATING_THUMBNAIL = 40,
  /** 全て完了 */
  COMPLETE = 100,
  /** S3キーが不正 */
  ERROR_INVALID_KEY = 900,
  /** 画像形式未サポート */
  ERROR_INVALID_IMAGE_FORMAT = 901,
  /** 低解像度 */
  ERROR_INVALID_IMAGE_RESOLUTION = 902,
  /** ファイルサイズ超過 */
  ERROR_INVALID_FILE_SIZE = 903,
  /** 予期せぬエラー */
  ERROR_UNEXPECTED = 999,
  /** タイムアウト */
  ERROR_TIMEOUT = 1000,
}
