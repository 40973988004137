<script setup lang="ts">
import LoadingFullScreen from '@/vueComponents/photographer/atoms/LoadingFullScreen/index.vue'
import { useLoadingOnCreateStates } from '.'

const states = useLoadingOnCreateStates()
</script>

<template>
  <LoadingFullScreen :is-shown="states.isShown" />
</template>
