<script setup lang="ts">
import { computed, defineEmits, defineProps } from 'vue'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Modal from '@/vueComponents/photographer/molecules/Modal/index.vue'
import { MESSAGE } from '@/utils/constants/messages/photographer/message'
import { IPasswordChangedModalProps, usePasswordChangedModalActions } from '.'

interface IProps {
  shown: IPasswordChangedModalProps['shown']
  location: IPasswordChangedModalProps['location']
}

interface IEmits {
  (e: 'update:shown', value: IProps['shown']): void
}

const props = defineProps<IProps>()

const emits = defineEmits<IEmits>()

const shownModel = computed<boolean>({
  get: () => props.shown,
  set: (shown) => emits('update:shown', shown),
})

usePasswordChangedModalActions(props)
</script>

<style lang="scss" module="css" scoped>
.body {
  padding: 20px 33px 47px;
}
</style>

<template>
  <Modal v-model:shown="shownModel" :closeable="false">
    <template #header>パスワードの設定完了 </template>
    <div :class="css.body">
      <Typography element="p" :line-breakable="true">{{
        MESSAGE.PASSWORD_RESET
      }}</Typography>
      <Typography
        >※自動的に移動しない場合は、<Link :location="props.location"
          >こちら</Link
        >を押してください。</Typography
      >
    </div>
  </Modal>
</template>
