<script setup lang="ts">
import { defineProps, defineEmits, withDefaults } from 'vue'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import { CheckboxProps, useCheckboxActions } from '.'

interface IProps {
  checked: CheckboxProps['checked']
  disabled?: CheckboxProps['disabled']
}

interface IEmits {
  (e: 'update:checked', value: IProps['checked']): void
}

const props = withDefaults(defineProps<IProps>(), {
  disabled: false,
})

const emits = defineEmits<IEmits>()

const actions = useCheckboxActions(props, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/mixins/position' as position-mixin;
.content {
  position: relative;
  cursor: pointer;
  background-color: colors.$background-color-content-primary;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid colors.$background-color-modal-border;
  &.checked {
    background-color: colors.$background-color-primary;
    border-color: transparent;
  }
}
.icon {
  @include position-mixin.translate-center;
  margin-top: -2px;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.3;
}
</style>

<template>
  <div
    :class="{
      [css.content]: true,
      [css.disabled]: props.disabled,
      [css.checked]: props.checked,
    }"
    @click="actions.handleOnClick"
  >
    <div v-if="props.checked" :class="css.icon"><Icon type="check-s" /></div>
  </div>
</template>
