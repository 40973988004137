<script setup lang="ts">
import { defineEmits } from 'vue'
import Input from '@/vueComponents/photographer/atoms/Input/index.vue'
import {
  NewPasswordInputEmitParams as EmitParams,
  useNewPasswordInputActions as useActions,
  useNewPasswordInputStates as useStates,
} from '.'

const states = useStates()
const emits = defineEmits<{
  (e: 'update:password', value: EmitParams['update:password']): void
  (
    e: 'update:validation-code',
    value: EmitParams['update:validation-code']
  ): void
}>()

useActions(states, emits)
</script>

<style lang="scss" module="css" scoped>
.confirm {
  margin-top: 9px;
}
</style>

<template>
  <div>
    <Input
      v-model:value="states.password"
      type="password"
      placeholder="新しいパスワード"
      :is-shadow="true"
    />
  </div>
  <div :class="css.confirm">
    <Input
      v-model:value="states.passwordConfirm"
      type="password"
      placeholder="パスワード確認"
      :is-shadow="true"
    />
  </div>
</template>
