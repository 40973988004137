import { AxiosResponse } from 'axios'
import { ApiError } from '.'

type ValidationErrors = Partial<{ [requestKey: string]: string[] }>

export class UnprocessableEntityError<
  Errors = ValidationErrors
> extends ApiError {
  public readonly errors: Errors | undefined

  constructor(response: AxiosResponse) {
    super('UnprocessableEntityError', 'バリデーションエラーが発生しました。')
    const responseData = response.data as { errors?: Errors } | undefined
    this.errors = responseData?.errors
  }
}

export const isUnprocessableEntityError = <
  Params extends object,
  Errors extends ValidationErrors = Partial<{
    [Param in keyof Params]: string[]
  }>
>(
  error: unknown
): error is UnprocessableEntityError<Errors> => {
  return !!(error as UnprocessableEntityError<Errors>).errors
}
