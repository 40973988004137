<script setup lang="ts">
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import { useBeforeLoginHeaderStates } from '.'

const states = useBeforeLoginHeaderStates()
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/sizes' as sizes;
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as position-mixins;
@use '@/styles/mixins/space' as space-mixins;
.wrapper {
  @include space-mixins.side-padding(4);
  @include position-mixins.flex-center;
  background-color: colors.$background-color-primary;
  height: 51px;
}
.page-title {
  @include space-mixins.side-padding(4);
  @include position-mixins.flex-center;
  background-color: colors.$background-color-header-title;
  height: 65px;
}
.inner {
  width: 100%;
  max-width: sizes.$width-header-content;
  display: flex;
  align-items: center;
}
.icon-base {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  @include position-mixins.flex-center;
  margin-right: space(3);
  background-color: colors.$background-color-primary;
  color: colors.$background-color-content-primary;
}
</style>

<template>
  <div :class="css.wrapper">
    <div :class="css.inner">
      <img :src="states.logoPath" alt="えんフォト" width="153" />
    </div>
  </div>
  <div v-if="states.pageTitle" :class="css['page-title']">
    <div :class="css.inner">
      <div :class="css['icon-base']">
        <Icon type="book" width="16px" />
      </div>
      <Typography>{{ states.pageTitle }}</Typography>
    </div>
  </div>
</template>
