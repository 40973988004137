import loginPcImg from '@/media/img/header/login_pc.jpg'
import loginSpImg from '@/media/img/header/login_sp.jpg'
import logoImg from '@/media/img/logo/en-photo/main.png'
import { addPublicBasePath } from '@/utils/functions/vite'

type LoginHeaderStates = {
  readonly backgroundImgUrlPc: string
  readonly backgroundImgUrlSp: string
  readonly logoImgUrl: string
}

export const useLoginHeaderStates = (): LoginHeaderStates => ({
  backgroundImgUrlPc: `url(${addPublicBasePath(loginPcImg)}`,
  backgroundImgUrlSp: `url(${addPublicBasePath(loginSpImg)}`,
  logoImgUrl: addPublicBasePath(logoImg),
})
