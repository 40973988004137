import { Emits } from '@/types/utils'
import { ValidationCode } from '@/utils/constants/enums/validation'
import { validatePasswordConfirm } from '@/utils/functions/validation'
import { reactive, watch } from 'vue'

interface INewPasswordInputStates {
  password: string
  passwordConfirm: string
  validationCode: ValidationCode
}

export type NewPasswordInputEmitParams = {
  'update:password': INewPasswordInputStates['password']
  'update:validation-code': INewPasswordInputStates['validationCode']
}

export const useNewPasswordInputStates = () =>
  reactive<INewPasswordInputStates>({
    password: '',
    passwordConfirm: '',
    validationCode: ValidationCode.EMPTY,
  })

export const useNewPasswordInputActions = (
  states: INewPasswordInputStates,
  emits: Emits<NewPasswordInputEmitParams>
) => {
  const validateAndEmitPassword = (): void => {
    states.validationCode = validatePasswordConfirm(
      states.password,
      states.passwordConfirm
    )
    emits('update:validation-code', states.validationCode)
    emits(
      'update:password',
      states.validationCode === ValidationCode.SUCCESS ? states.password : ''
    )
  }

  watch(() => states.password, validateAndEmitPassword)
  watch(() => states.passwordConfirm, validateAndEmitPassword)
}
