import Bugsnag from '@bugsnag/browser'

type BugsnagUserData = {
  id?: string
  name?: string
  email?: string
}

/**
 * Bugsnag通知用ユーザー情報の設定
 * @param data ユーザー情報
 */
export const setBugsnagUserData = (data: BugsnagUserData): void =>
  Bugsnag.setUser(data.id, data.name, data.email)
