import { reactive } from 'vue'
import logoWhite from '@/media/img/logo_white.png'
import { addPublicBasePath } from '@/utils/functions/vite'
import { useRoute } from 'vue-router'

type BeforeLoginHeaderStates = {
  logoPath: string
  pageTitle: string
}

export const useBeforeLoginHeaderStates = () => {
  const route = useRoute()
  return reactive<BeforeLoginHeaderStates>({
    logoPath: addPublicBasePath(logoWhite),
    pageTitle: route.meta.title as string,
  })
}
