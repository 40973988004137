<script setup lang="ts">
import { defineEmits } from 'vue'
import { FormEmitParams, useFormActions } from '.'

interface IEmits {
  (e: 'enter', value: FormEmitParams['enter']): void
}

const emits = defineEmits<IEmits>()

const actions = useFormActions(emits)
</script>

<template>
  <div @keydown.enter.capture="actions.onEnter">
    <slot />
  </div>
</template>
