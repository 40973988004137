export const enum RequesterEventStatusCode {
  /** 開催待ち */
  WAITING_EVENT = 1,
  /** 開催終了 */
  CLOSED = 2,
  /** 納品済み */
  DELIVERED = 3,
  /** キャンセル */
  CANCELLED = 4,
}

export const enum ZipDownload {
  /** 同時実行数 */
  MAX_CONCURRENT = 6,
}
