import { ElementOf } from '@/types/utils'

export const isString = (value: unknown): value is string =>
  typeof value === 'string'

export const isArray = (value: unknown): value is Array<unknown> =>
  value instanceof Array

export const isStringArray = (value: unknown): value is Array<string> =>
  isArray(value) && value.every((element) => isString(element))

export const isElementOfTupple = <T extends Readonly<Array<unknown>>>(
  tupple: T,
  value: unknown
): value is ElementOf<T> => tupple.includes(value)

export function assertString(value: unknown): asserts value is string {
  if (!isString(value)) {
    throw new Error(`文字列ではない値「${String(value)}」が渡されました。`)
  }
}
