<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/sizes' as sizes;
.text {
  color: colors.$font-color-footer;
  font-size: sizes.$font-size-footer;
}
</style>

<template>
  <div :class="css.text">Copyright © ULURU.CO.LTD. All Rights Reserved.</div>
</template>
