import { photographerConstants } from '@/utils/constants/photographerConstants'
import { watch } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'

export interface IPasswordChangedModalProps {
  shown: boolean
  location: RouteLocationRaw
}

export const usePasswordChangedModalActions = (
  props: IPasswordChangedModalProps
) => {
  const router = useRouter()
  let isRedirecting = false
  const setRedirection = (): void => {
    setTimeout(
      async () => await router.push(props.location),
      photographerConstants.AUTO_REDIRECT_SECONDS * 1000
    )
    isRedirecting = true
  }

  watch(
    () => props.shown,
    (isShown) => {
      if (isShown && !isRedirecting) setRedirection()
    }
  )
}
