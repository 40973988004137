<script setup lang="ts">
import { TableProps, useTableActions, useTableStates } from '.'

interface IProps {
  scrollable?: TableProps['scrollable']
}

const props = defineProps<IProps>()

const states = useTableStates(props)

useTableActions(states)
</script>

<style lang="scss" module>
.wrapper {
  overflow-x: auto;
}
.table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
}
.scrollable {
  display: block;

  thead,
  tbody,
  tfoot {
    display: block;

    tr {
      display: grid;
      grid-template-columns: v-bind('states.gridTemplateColumns');
      grid-auto-rows: min-content;

      td,
      th {
        grid-column: span 1;
        height: 100%;

        &[colspan='2'] {
          grid-column: span 2;
        }
      }
    }
  }

  tbody {
    max-height: v-bind('states.tableBodyMaxHeight');
    overflow: auto;
  }
}
.overflown {
  thead,
  tfoot {
    tr {
      grid-template-columns: v-bind('states.overflownGridTemplateColumns');
    }
  }
}
</style>

<template>
  <div :class="$style.wrapper">
    <table
      :ref="(table) => states.table = (table as HTMLElement)"
      :class="[
        $style.table,
        {
          [$style.scrollable]: states.isScrollable,
          [$style.overflown]: states.isOverflown,
        },
      ]"
    >
      <colgroup>
        <slot name="col"></slot>
      </colgroup>
      <thead>
        <slot name="head"></slot>
      </thead>
      <tbody :ref="(tbody) => states.tableBody = (tbody as HTMLElement)">
        <slot></slot>
      </tbody>
      <tfoot>
        <slot name="foot"></slot>
      </tfoot>
    </table>
  </div>
</template>
