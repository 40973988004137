import { IAxiosConfigData } from '@/types/utils'
import { pluginKeys } from '@/utils/constants/pluginKeys'
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { inject } from 'vue'

export class BaseApi {
  private readonly axios: AxiosInstance

  constructor() {
    const axios = inject<AxiosInstance>(pluginKeys.AXIOS)
    if (!axios) throw new Error('Axios plugin has not provided.')
    this.axios = axios
  }

  /**
   * GET
   * @template Response レスポンスの型
   * @param path APIエンドポイント
   * @param data パスパラメータ
   * @param config リクエストコンフィグ
   * @param noCache キャッシュ無効化フラグ
   * @returns レスポンス
   */
  protected get<Response = null>(
    path: string,
    data?: IAxiosConfigData,
    config?: AxiosRequestConfig,
    noCache = true
  ): Promise<AxiosResponse<Response>> {
    const noCacheHeaders = noCache
      ? {
          headers: {
            'Cache-Control': 'no-cache, no-store',
          },
        }
      : null
    return this.axios.get<Response>(
      path,
      Object.assign({ data }, noCacheHeaders, config)
    )
  }

  /**
   * POST
   * @template Request リクエストの型
   * @template Response レスポンスの型
   * @param path APIエンドポイント
   * @param data リクエストデータ
   * @param config リクエストコンフィグ
   * @returns レスポンス
   */
  protected post<Request = null, Response = null>(
    path: string,
    data?: Request,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<Response>> {
    return this.axios.post<Response>(path, data, config)
  }

  /**
   * PUT
   * @template Request リクエストの型
   * @template Response レスポンスの型
   * @param path APIエンドポイント
   * @param data リクエストデータ
   * @param config リクエストコンフィグ
   * @returns レスポンス
   */
  protected put<Request = null, Response = null>(
    path: string,
    data?: Request,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<Response>> {
    return this.axios.put<Response>(path, data, config)
  }

  /**
   * DELETE
   * @template Request リクエストの型
   * @template Response レスポンスの型
   * @param path APIエンドポイント
   * @param data リクエストデータ
   * @returns レスポンス
   */
  protected delete<Request = null, Response = null>(
    path: string,
    data?: Request
  ): Promise<AxiosResponse<Response>> {
    return this.axios.delete<Response>(path, { data })
  }
}
