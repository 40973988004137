export const enum EventStatusCode {
  /** 受注済み */
  ORDERED = 1,
  /** 開催待ち */
  WAITING_EVENT = 2,
  /** 納品前 */
  BEFORE_DELIVERY = 3,
  /** キャンセル */
  CANCELLED = 4,
  /** 検品待ち */
  WAITING_INSPECTION = 5,
  /** 検品中 */
  INSPECTING = 6,
  /** 差し戻し中 */
  SENDING_BACK = 7,
  /** 納品完了 */
  DELIVERED = 8,
  /** 回答前 */
  BEFORE_ANSWER = 9,
}

export const enum EventTypeCode {
  /** 通常 */
  AS_PLANNED = 1,
  /** 順延 */
  POSTPONED = 2,
}

export const enum InspectionStatusCode {
  /** 検品待ち */
  WAITING_INSPECTION = 1,
  /** 差し戻し */
  SENDING_BACK = 2,
  /** 検品完了 */
  INSPECTION_COMPLETE = 3,
}
