import { CreatingEntity } from '@/entities/global/CreatingEntity'
import { Promisable } from '@/types/utils'
import { onBeforeMount } from 'vue'

/**
 * 初期表示時処理の実行
 * @desc 非同期処理の実行中にローディングモーダルを表示する
 * @param action 初期表示時処理
 */
export const onCreate = (action: () => Promisable<void>): void => {
  // TODO Suspenseが正式リリースされたらonBeforeMountを外す
  onBeforeMount(async () => await createOnAsync(new CreatingEntity())(action))
}

/**
 * 非同期通信中処理の生成
 * @param creatingEntity 非同期中管理エンティティ
 * @param symbolName シンボル名
 * @returns 非同期通信中処理
 */
export const createOnAsync = (
  creatingEntity: CreatingEntity,
  symbolName = 'action'
) => {
  const actionSymbol = Symbol(symbolName)
  return async <T>(action: () => Promisable<T>) => {
    // 同一関数オブジェクトで同時に実行できる非同期処理は1つまで
    if (creatingEntity.hasCreateSymbol(actionSymbol)) return
    creatingEntity.addCreateSymbol(actionSymbol)
    try {
      return await action()
    } finally {
      creatingEntity.deleteCreateSymbol(actionSymbol)
    }
  }
}
