<script setup lang="ts">
import { computed, defineProps, withDefaults } from 'vue'
import { ITypographyProps } from '.'

interface IProps {
  type?: ITypographyProps['type']
  element?: ITypographyProps['element']
  textAlign?: ITypographyProps['textAlign']
  lineBreakable?: ITypographyProps['lineBreakable']
  hoverType?: ITypographyProps['hoverType']
}

const props = withDefaults(defineProps<IProps>(), {
  type: 'read-normal',
  element: 'div',
  textAlign: 'inherit',
  lineBreakable: false,
  hoverType: undefined,
})

const computedElement = computed<string>(() => {
  switch (props.type) {
    case 'h1':
      return 'h1'
    case 'h2':
    case 'h2-simple':
    case 'h2-headline':
      return 'h2'
    case 'h3':
      return 'h3'
    default:
      return props.element
  }
})
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/font' as font-mixins;
@use '@/styles/mixins/typography' as typography-mixins;
@use '@/styles/sizes' as sizes;
@use '@/styles/colors' as colors;
@use '@/styles/functions/space' as *;
.type {
  &-count {
    &-normal {
      @include typography-mixins.typography-type-base(sizes.$font-size-3l, 1);
      @include font-mixins.font-weight-medium;
    }
    &-error {
      @extend .type-count-normal;
      color: colors.$font-color-attention;
    }
  }
  &-h1 {
    @include typography-mixins.typography-type-base(
      sizes.$font-size-5l,
      1.2,
      colors.$background-color-primary
    );
    @include font-mixins.font-weight-bold;
  }
  &-h2-headline {
    @include typography-mixins.typography-type-base(sizes.$font-size-4l, 1.2);
    @include font-mixins.font-weight-medium;
  }
  &-h2-simple {
    @extend .type-h2-headline;
    @include typography-mixins.typography-before-square(
      colors.$background-color-seller-main,
      space(2),
      3px
    );
  }
  &-h2 {
    @extend .type-h2-simple;
    padding-bottom: space(6);
    border-bottom: 1px solid colors.$border-color-separator;
  }
  &-h3 {
    @include typography-mixins.typography-type-base(sizes.$font-size-xl, 1.4);
    @include font-mixins.font-weight-medium;
    padding: 1px 0;
    @include typography-mixins.typography-before-square(
      colors.$background-color-light,
      space(1),
      3px
    );
  }
  &-bread-crumb {
    @include typography-mixins.typography-type-base(sizes.$font-size-sm, 1.2);
    @include font-mixins.font-weight-medium;
  }
  &-head {
    @include typography-mixins.typography-type-base;
    @include font-mixins.font-weight-bold;
    &-green {
      @extend .type-head;
      color: colors.$font-color-white;
    }
    &-large {
      @include typography-mixins.typography-type-base(sizes.$font-size-lg);
      @include font-mixins.font-weight-bold;
    }
  }
  &-label {
    @include typography-mixins.typography-type-base;
    @include font-mixins.font-weight-medium;
  }
  &-read {
    &-normal {
      @include typography-mixins.typography-type-base;
      @include font-mixins.font-weight-medium;
      &-disabled {
        @extend .type-read-normal;
        color: colors.$font-color-disabled;
      }
      &-attention {
        @extend .type-read-normal;
        color: colors.$font-color-attention;
        &-bold {
          @extend .type-read-normal-attention;
          @include font-mixins.font-weight-bold;
        }
      }
      &-bold {
        @extend .type-read-normal;
        @include font-mixins.font-weight-bold;
      }
    }
    &-large {
      @include typography-mixins.typography-type-base(sizes.$font-size-lg);
      @include font-mixins.font-weight-medium;
    }
    &-small {
      @include typography-mixins.typography-type-base(sizes.$font-size-sm);
      @include font-mixins.font-weight-medium;
      &-disabled {
        @extend .type-read-small;
        color: colors.$font-color-disabled;
        &-deep {
          @extend .type-read-small-disabled;
          color: colors.$font-color-disabled-deep;
        }
      }
      &-attention {
        @extend .type-read-small;
        color: colors.$font-color-attention;
        &-bold {
          @extend .type-read-small-attention;
          @include font-mixins.font-weight-bold;
        }
      }
      &-white {
        @extend .type-read-small;
        color: colors.$font-color-white;
      }
      &-bold {
        @extend .type-read-small;
        @include font-mixins.font-weight-bold;
      }
    }
    &-tiny {
      @include typography-mixins.typography-type-base(sizes.$font-size-xs);
      @include font-mixins.font-weight-medium;
      &-white {
        @extend .type-read-tiny;
        color: colors.$font-color-white;
      }
    }
    &-x-tiny {
      @include typography-mixins.typography-type-base(sizes.$font-size-xxs);
      @include font-mixins.font-weight-medium;
      color: colors.$font-color-x-tiny;
      &-disabled {
        @extend .type-read-x-tiny;
        color: colors.$font-color-disabled;
      }
    }
  }
  &-upload-main {
    @include typography-mixins.typography-type-base(sizes.$font-size-lg);
    @include font-mixins.font-weight-medium;
  }
  &-page-error {
    @include typography-mixins.typography-type-base;
    @include font-mixins.font-weight-medium;
    color: colors.$font-color-attention;
    &-lg {
      @extend .type-page-error;
      font-size: sizes.$font-size-lg;
    }
    &-xl {
      @extend .type-page-error;
      font-size: sizes.$font-size-xl;
    }
  }
  &-notice {
    @include typography-mixins.typography-type-base(sizes.$font-size-lg);
    @include font-mixins.font-weight-medium;
    color: colors.$font-color-notice;
  }
  &-unique {
    &-fullmodal-close {
      @include typography-mixins.typography-type-base(
        sizes.$font-size-fullmodal-close
      );
    }
  }
  &-material-preparation {
    &-title {
      @include font-mixins.font-weight-bold;
      @include typography-mixins.typography-type-base(
        sizes.$font-size-contentbox-title2
      );
    }
    &-subtitle {
      @include font-mixins.font-weight-bold;
      @include typography-mixins.typography-type-base(sizes.$font-size-lg);
    }
  }
}

.is-hover {
  &-header-icon {
    color: colors.$font-color-hover-gray;
  }
}

.line-break {
  white-space: pre-line;
}
</style>

<template>
  <component
    :is="computedElement"
    :class="{
      [css[`type-${props.type}`]]: true,
      [css['line-break']]: props.lineBreakable,
      [css[`is-hover-${props.hoverType}`]]: props.hoverType,
    }"
    :style="{ textAlign: props.textAlign }"
    ><slot
  /></component>
</template>
