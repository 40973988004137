import { CreatingEntity } from '@/entities/global/CreatingEntity'
import { Computable } from '@/types/utils'
import { computed, reactive } from 'vue'

interface ILoadingOnCreateStates {
  isShown: boolean
}

export const useLoadingOnCreateStates = () => {
  const creatingEntity = new CreatingEntity()

  return reactive<Computable<ILoadingOnCreateStates>>({
    isShown: computed((): boolean => creatingEntity.isCreating),
  })
}
