import { Computable, Emits } from '@/types/utils'
import { CheckboxProps } from '@/vueComponents/photographer/molecules/Checkbox'
import { computed, reactive } from 'vue'
import { ITypographyProps } from '../../atoms/Typography'

export type CheckboxWithLabelProps = CheckboxProps & {
  type: 'normal' | 'tablehead'
  hideCheckbox?: boolean
}

export type CheckboxWithLabelEmitParams = {
  'update:checked': CheckboxWithLabelProps['checked']
}

type CheckboxWithLabelStates = {
  checked: boolean
  textType: ITypographyProps['type']
}

export const useCheckboxWithLabelStates = (
  props: CheckboxWithLabelProps,
  emits: Emits<CheckboxWithLabelEmitParams>
): CheckboxWithLabelStates =>
  reactive<Computable<CheckboxWithLabelStates>>({
    checked: computed({
      get() {
        return !!props.checked
      },
      set(value) {
        emits('update:checked', value)
      },
    }),
    textType: computed(() =>
      props.disabled
        ? 'read-normal-disabled'
        : props.type === 'tablehead'
        ? 'head'
        : 'read-normal'
    ),
  })

export const useCheckboxWithLabelActions = (
  states: CheckboxWithLabelStates,
  emits: Emits<CheckboxWithLabelEmitParams>
) => {
  return {
    onClick() {
      if (states.checked) {
        emits('update:checked', false)
      } else {
        emits('update:checked', true)
      }
    },
  }
}
