import { ApiError } from '.'

export class UnauthorizedError extends ApiError {
  constructor() {
    super('UnauthorizedError', 'ログイン情報がありません。')
  }
}

/**
 * セッション切れエラー判定
 * @param e エラーオブジェクト
 * @returns true:セッション切れエラーである false:セッション切れエラーではない
 */
export const isUnauthorizedError = (e: Error): e is UnauthorizedError => {
  return e instanceof UnauthorizedError
}
