import * as FAR from '@fortawesome/free-regular-svg-icons'
import * as FAS from '@fortawesome/free-solid-svg-icons'
import { IconLookup } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Computable } from '@/types/utils'
import { computed, reactive, DefineComponent } from 'vue'
import { ILinkProps } from '@/vueComponents/photographer/atoms/Link/index'

type FontAwesomeIconConstructor = typeof FontAwesomeIcon

type FontAwesomeIconProps = FontAwesomeIconConstructor extends DefineComponent<
  infer T
>
  ? T
  : never

export type IconProps = {
  type:
    | 'alert-large'
    | 'arrow-medium-down'
    | 'arrow-medium-right'
    | 'arrow-medium-left'
    | 'arrow-medium-up'
    | 'book'
    | 'calendar'
    | 'camera'
    | 'campground'
    | 'check-s'
    | 'child'
    | 'circle'
    | 'clock'
    | 'close'
    | 'deadline'
    | 'error-page-email'
    | 'error-page-phone'
    | 'event-info-clock'
    | 'folder-gray'
    | 'landmark'
    | 'header-bell'
    | 'header-camera'
    | 'header-logout'
    | 'header-schedule'
    | 'header-file-alt'
    | 'header-account'
    | 'login-page-link'
    | 'notification'
    | 'map-marker'
    | 'plus'
    | 'green-plus'
    | 'photo-video'
    | 'question'
    | 'square'
    | 'bell'
    | 'yen-sign'
    | 'calendar-alt'
    | 'flag'
    | 'external-link-alt'
    | 'download'
    | 'trash-alt'
    | 'paperclip'
  isBlock?: boolean
  disabled?: boolean
  width?: string
  hoverType?: ILinkProps['hoverType']
}

type IIconStates = Partial<FontAwesomeIconProps>

class IconData {
  readonly #type: IconProps['type']
  constructor(type: IconProps['type']) {
    this.#type = type
  }

  get #lookup(): IconLookup | null {
    switch (this.#type) {
      case 'alert-large':
        return FAS.faExclamationTriangle
      case 'arrow-medium-down':
        return FAS.faAngleDown
      case 'arrow-medium-right':
        return FAS.faAngleRight
      case 'arrow-medium-left':
        return FAS.faAngleLeft
      case 'arrow-medium-up':
        return FAS.faAngleUp
      case 'book':
        return FAS.faBook
      case 'calendar':
      case 'header-schedule':
        return FAS.faCalendarAlt
      case 'header-file-alt':
        return FAS.faFileAlt
      case 'camera':
        return FAS.faCamera
      case 'campground':
        return FAS.faCampground
      case 'check-s':
        return FAS.faCheck
      case 'child':
        return FAS.faChild
      case 'circle':
        return FAS.faCircle
      case 'clock':
        return FAR.faClock
      case 'close':
        return FAS.faTimes
      case 'deadline':
        return FAS.faHistory
      case 'error-page-email':
        return FAS.faEnvelope
      case 'error-page-phone':
        return FAS.faPhoneAlt
      case 'event-info-clock':
        return FAS.faClock
      case 'folder-gray':
        return FAS.faFolder
      case 'landmark':
        return FAS.faLandmark
      case 'login-page-link':
        return FAS.faPlayCircle
      case 'header-logout':
        return FAS.faSignOutAlt
      case 'header-camera':
        return FAS.faCamera
      case 'map-marker':
        return FAS.faMapMarkerAlt
      case 'notification':
        return FAS.faFireAlt
      case 'plus':
      case 'green-plus':
        return FAS.faPlus
      case 'photo-video':
        return FAS.faPhotoVideo
      case 'question':
        return FAS.faQuestionCircle
      case 'square':
        return FAS.faSquare
      case 'bell':
      case 'header-bell':
        return FAS.faBell
      case 'header-account':
        return FAS.faUserCircle
      case 'yen-sign':
        return FAS.faYenSign
      case 'calendar-alt':
        return FAS.faCalendarAlt
      case 'flag':
        return FAS.faFlag
      case 'external-link-alt':
        return FAS.faExternalLinkAlt
      case 'download':
        return FAS.faDownload
      case 'trash-alt':
        return FAS.faTrashAlt
      case 'paperclip':
        return FAS.faPaperclip
      default:
        return null
    }
  }

  public get icon(): FontAwesomeIconProps['icon'] {
    if (!this.#lookup) return []
    return [this.#lookup.prefix, this.#lookup.iconName]
  }

  public get size(): FontAwesomeIconProps['size'] {
    switch (this.#type) {
      case 'circle':
      case 'check-s':
      case 'clock':
      case 'book':
      case 'square':
      case 'landmark':
      case 'login-page-link':
      case 'notification':
        return 'xs' // 12px
      case 'calendar':
      case 'camera':
      case 'flag':
      case 'campground':
      case 'child':
      case 'close':
      case 'deadline':
      case 'error-page-email':
      case 'error-page-phone':
      case 'event-info-clock':
      case 'map-marker':
      case 'photo-video':
      case 'yen-sign':
        return 'sm' // 14px
      case 'header-bell':
      case 'header-camera':
      case 'header-logout':
      case 'header-schedule':
      case 'header-file-alt':
      case 'header-account':
        return '2x' // 32px
      default:
        return '1x' // 16px
    }
  }
}

export const useIconStates = (props: IconProps) => {
  const iconData = computed(() => new IconData(props.type))

  return reactive<Computable<IIconStates>>({
    icon: computed(() => iconData.value.icon),
    size: computed(() => iconData.value.size),
  })
}
