import { ValidationCode } from '@/utils/constants/enums/validation'
import { photographerConstants } from '@/utils/constants/photographerConstants'

const { FILE_UPLOAD } = photographerConstants

export const validateMail = (mail: string): ValidationCode => {
  const reg =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/
  if (!mail) return ValidationCode.EMPTY
  if (mail.length > 254) return ValidationCode.TOO_LONG
  if (!reg.test(mail)) return ValidationCode.PATTERN_MISMATCH
  return ValidationCode.SUCCESS
}

export const validatePassword = (password: string): ValidationCode => {
  const reg = /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,20}$/i
  if (!password) return ValidationCode.EMPTY
  if (password.length < 8) return ValidationCode.TOO_SHORT
  if (password.length > 20) return ValidationCode.TOO_LONG
  if (!reg.test(password)) return ValidationCode.PATTERN_MISMATCH
  return ValidationCode.SUCCESS
}

export const validatePasswordConfirm = (
  password: string,
  passwordConfirm: string
): ValidationCode => {
  const passwordValidationCode = validatePassword(password)
  if (passwordValidationCode !== ValidationCode.SUCCESS) {
    return passwordValidationCode
  }
  if (password !== passwordConfirm) return ValidationCode.NOT_THE_SAME
  return ValidationCode.SUCCESS
}

/**
 * ログインIDのバリデーションチェック
 * @param loginId ログインID
 * @returns ValidationCode
 */
export const validateLoginId = (loginId: string): ValidationCode => {
  const reg = /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/
  if (!loginId) return ValidationCode.EMPTY
  if (loginId.length > 50) return ValidationCode.TOO_LONG
  if (!reg.test(loginId)) return ValidationCode.PATTERN_MISMATCH
  return ValidationCode.SUCCESS
}

type FileValidationResult = {
  validationCode: ValidationCode
  height?: number
  width?: number
}

/**
 * ファイルサイズ判定
 * @param file 判定対象ファイル
 * @returns バリデーションコード
 */
const validateFileSize = (file: File): FileValidationResult => ({
  validationCode: !isUploadableFileSize(file)
    ? ValidationCode.FILE_SIZE_TOO_LARGE
    : ValidationCode.SUCCESS,
})

/**
 * アップロード可否判定（ファイルサイズ）
 * @param file 判定対象ファイル
 * @returns true:アップロード可 false:アップロード不可
 */
export const isUploadableFileSize = (file: File): boolean =>
  file.size <= FILE_UPLOAD.MAX_SIZE

/**
 * アップロード可否判定（拡張子）
 * @param arrayBuffer 判定対象ファイルのバイナリデータ
 * @returns true:アップロード可 false:アップロード不可
 */
const isUploadableFileType = (arrayBuffer: ArrayBuffer): boolean => {
  const headerArr = new Uint8Array(arrayBuffer).subarray(0, 4)
  const header = headerArr.reduce((last, curr) => last + curr.toString(16), '')
  return FILE_UPLOAD.ACCEPTABLE_TYPE_HEADER_REG.test(header)
}

/**
 * ファイル読み込み完了時処理の生成
 * @param resolve 非同期処理の完了処理
 * @returns ファイル読み込み完了時処理
 */
const createOnloadendFileReader =
  (resolve: (value: ValidationCode) => void) =>
  (event: ProgressEvent<FileReader>): void => {
    const arrayBuffer = event.target?.result
    if (!arrayBuffer || typeof arrayBuffer === 'string') {
      return resolve(ValidationCode.UNEXPECTED_VALUE)
    }
    if (!isUploadableFileType(arrayBuffer)) {
      return resolve(ValidationCode.NOT_ACCEPTED)
    }
    resolve(ValidationCode.SUCCESS)
  }

/**
 * ファイル拡張子判定
 * @param file 判定対象ファイル
 * @returns バリデーションコード
 */
const validateFileType = async (file: File): Promise<FileValidationResult> => {
  const fileReader = new FileReader()
  const validationCode = await new Promise<ValidationCode>((resolve) => {
    fileReader.onloadend = createOnloadendFileReader(resolve)
    fileReader.readAsArrayBuffer(file)
  })
  return { validationCode }
}

/**
 * ファイル解像度判定
 * @param file 判定対象ファイル
 * @returns バリデーションコード
 */
// TODO サーバーサイドで判定することになったが、仕様変更に対応できるようexportで残しておく
export const validateFileResolution = async (
  file: File
): Promise<FileValidationResult> => {
  const image = new Image()
  return await new Promise<FileValidationResult>((resolve) => {
    image.onload = () =>
      resolve({
        validationCode: isUploadableFileResolution(image)
          ? ValidationCode.SUCCESS
          : ValidationCode.RESOLUTION_TOO_LOW,
        height: image.height,
        width: image.width,
      })
    image.src = URL.createObjectURL(file)
  })
}

/**
 * アップロード可否判定（解像度）
 * @param image 画像情報
 * @returns true:アップロード可 false:アップロード不可
 */
const isUploadableFileResolution = (image: HTMLImageElement): boolean =>
  image.width >= FILE_UPLOAD.MIN_SIDE_LENGTH &&
  image.height >= FILE_UPLOAD.MIN_SIDE_LENGTH

/**
 * 画像ファイル判定
 * @param file 判定対象ファイル
 * @returns バリデーションコードと解像度
 */
export const validateFile = async (
  file: File
): Promise<FileValidationResult> => {
  const validators = [validateFileSize, validateFileType]
  const result: FileValidationResult = {
    validationCode: ValidationCode.SUCCESS,
  }
  try {
    for (const validator of validators) {
      Object.assign(result, await validator(file))
      if (result.validationCode !== ValidationCode.SUCCESS) return result
    }
    return result
  } catch {
    return { validationCode: ValidationCode.UNEXPECTED_ERROR }
  }
}
