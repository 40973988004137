import { ERRORMSG } from './errorMsg'
import { MESSAGE } from './message'
import { TAB } from './tabTitle'
import { TRANSPORTATION } from './transportation'
import { EVENT_STATUS, DISPATCH_TYPE } from './event/index'

export const PHOTOGRAPHER = {
  SERVICE_NAME: 'えんフォト',
  PAGE_TITLE: {
    LOGIN: 'フォトグラファーログインページ',
    PASSWORD_RESET: 'パスワード再設定',
    PASSWORD_UPDATE: 'パスワード変更',
    EVENTS: 'イベント一覧',
    TOP: 'ホーム',
    NOTIFICATIONS: '通知一覧',
    SCHEDULE: 'スケジュール登録',
    INVOICES: '請求書',
  },
  UNDEFINED_DATE: '未設定',
  ERRORMSG,
  MESSAGE,
  EVENT_STATUS,
  DISPATCH_TYPE,
  TAB,
  TRANSPORTATION,
} as const
