export const EVENT_STATUS = {
  ORDERED: '受注済み',
  WAITING_EVENT: '開催待ち',
  BEFORE_DELIVERY: '納品前',
  CANCELLED: 'キャンセル',
  WAITING_INSPECTION: '検品待ち',
  INSPECTING: '検品中',
  SENDING_BACK: '差し戻し中',
  DELIVERED: '納品完了',
  BEFORE_ANSWER: '回答前',
} as const

export const DISPATCH_TYPE = {
  AS_PLANNED: '通常',
  POSTPONED: '順延',
} as const

export const FOLDER_NAME_SETTING_LABEL = {
  CREATE: {
    TITLE: '新しいフォルダを作成しますか？',
    BUTTON: '作成する',
  },
  UPDATE: {
    TITLE: 'フォルダ名を変更しますか？',
    BUTTON: '変更する',
  },
} as const

export const UPLOAD_MODAL_HEADER_TITLE = {
  COMPLETED: 'アップロード完了',
  DUPLICATION: '項目を上書きしますか？',
  FAILED: 'アップロード失敗',
  PROGRESS: 'アップロード中',
} as const
