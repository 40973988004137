<script setup lang="ts">
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { defineProps, withDefaults } from 'vue'
import { IconProps, useIconStates } from '.'

library.add(fas, far, fab)

interface IProps {
  type: IconProps['type']
  isBlock?: IconProps['isBlock']
  disabled?: IconProps['disabled']
  width?: IconProps['width']
  hoverType?: IconProps['hoverType']
}

const props = withDefaults(defineProps<IProps>(), {
  isBlock: false,
  disabled: false,
  width: undefined,
  hoverType: undefined,
})

const states = useIconStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
.icon {
  &.is-block {
    display: block;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.type {
    &-alert-large {
      color: colors.$font-color-attention;
    }
    &-arrow {
      &-medium {
        &-down,
        &-right,
        &-up {
          font-size: 26px;
        }
      }
    }
    &-bell,
    &-calendar-alt {
      color: colors.$background-color-primary;
    }
    &-check-s {
      color: colors.$background-color-content-primary;
    }
    &-clock {
      color: colors.$background-color-primary;
    }
    &-close {
      color: colors.$background-color-content-primary;
    }
    &-error-page {
      &-email,
      &-phone {
        color: colors.$font-color-primary;
      }
    }
    &-download {
      color: colors.$font-color-primary;
    }
    &-folder-gray {
      color: colors.$background-color-icon-gray;
    }
    &-login-page-link {
      color: colors.$background-color-seller-main;
    }
    &-header {
      &-bell,
      &-camera,
      &-logout,
      &-schedule,
      &-account {
        color: colors.$background-color-content-primary;
      }
    }
    &-notification {
      color: colors.$background-color-primary;
    }
    &-plus {
      color: colors.$font-color-notice;
    }
    &-green-plus {
      color: colors.$font-color-notice;
    }
    &-photo-video {
      color: colors.$background-color-icon-gray;
    }
    &-question {
      color: colors.$background-color-primary;
    }
    &-calendar,
    &-camera,
    &-campground,
    &-child,
    &-deadline,
    &-event-info-clock,
    &-flag,
    &-map-marker {
      color: colors.$background-color-icon-gray;
    }
    &-yen-sign {
      color: colors.$background-color-icon-gray;
    }
  }
  &.size {
    width: v-bind('props.width');
    height: auto;
  }
  &.is-hover {
    &-header-icon {
      color: colors.$font-color-hover-gray;
    }
    &-green-plus {
      color: colors.$font-color-white;
    }
  }
}
</style>

<template>
  <font-awesome-icon
    :icon="states.icon"
    :size="!props.width ? states.size : null"
    :class="{
      [css.icon]: true,
      [css[`type-${props.type}`]]: !!css[`type-${props.type}`],
      [css.size]: !!props.width,
      [css['is-block']]: props.isBlock,
      [css['disabled']]: props.disabled,
      [css[`is-hover-${props.hoverType}`]]: props.hoverType,
    }"
  />
</template>
