import { format, parse } from 'date-fns'
import ja from 'date-fns/locale/ja'
import { isString, isStringArray } from '../typeCheck'

/**
 * 任意の方の値を10進数の数値に変換
 * @param value 値
 * @returns 10進数の数値
 */
export const parseNumber = (value: unknown): number => {
  switch (typeof value) {
    case 'number':
      return value
    case 'string':
      return parseInt(value, 10) || 0
    default:
      return 0
  }
}

export const parseExtendedStringToString = (value: unknown): string => {
  if (isString(value)) return value
  if (isStringArray(value)) return value[0]
  return ''
}

/**
 * Dateオブジェクトを「yyyy/MM/dd」形式の文字列に変換
 */
export const parseDateYearToString = (date: Date) => format(date, 'yyyy/MM/dd')

/**
 * Dateオブジェクトを「yy/MM/dd」形式の文字列に変換
 */
export const parseDateSimpleYearToString = (date: Date) =>
  format(date, 'yy/MM/dd')

/**
 * Dateオブジェクトを「yyyy-MM-dd」形式の文字列に変換
 */
export const parseDateYearToHyphenString = (date: Date) =>
  format(date, 'yyyy-MM-dd')

/**
 * Dateオブジェクトを「yyyy-MM」形式の文字列に変換
 */
export const parseMonthYearToHyphenString = (date: Date) =>
  format(date, 'yyyy-MM')

/**
 * Dateオブジェクトを「MM/dd」形式の文字列に変換
 */
export const parseDateToString = (date: Date) => format(date, 'MM/dd')

/**
 * Dateオブジェクトを「yyyy年M月」形式の文字列に変換
 */
export const parseJaMonthYearToString = (date: Date) =>
  format(date, 'yyyy年M月')

/**
 * Dateオブジェクトを「yyyy年M月d日」形式の文字列に変換
 */
export const parseJaDateYearToString = (date: Date) =>
  format(date, 'yyyy年M月d日')

/**
 * Dateオブジェクトを「yyyy年M月d日(E)」形式の文字列に変換
 */
export const parseJaDateYearWithDayOfWeekToString = (date: Date) =>
  format(date, 'yyyy年M月d日(E)', { locale: ja })

/**
 * Dateオブジェクトを「yyyy/MM/dd(E)」形式の文字列に変換
 */
export const parseDateYearWithDayOfWeekToString = (date: Date) =>
  format(date, 'yyy/MM/dd(E)', { locale: ja })

/**
 * Dateオブジェクトを「M/d（E）」形式の文字列に変換
 */
export const parseDateDayOfWeekToString = (date: Date) =>
  format(date, 'M/d（E）', { locale: ja })

/**
 * Dateオブジェクトを「M月d日」形式の文字列に変換
 */
export const parseJaDateToString = (date: Date) => format(date, 'M月d日')

/**
 * Dateオブジェクトを「H:mm」形式の文字列に変換
 */
export const parseDateTimeToString = (date: Date) => format(date, 'H:mm')

export const parseZipCode = (zipCode: number): string =>
  `〒${String(zipCode).slice(0, 3)}-${String(zipCode).slice(
    3,
    String(zipCode).length
  )}`

/**
 * 3桁区切りでカンマを付与した文字列に変換
 */
export const parseAmount = (amount: number) => amount.toLocaleString()

/**
 * 文字列の'true', 'false'をbooleanに変換
 * 'true'以外の文字列は全て false になります
 */
export const toBoolean = (booleanStr: string) =>
  booleanStr.toLocaleLowerCase() === 'true'

/**
 * 文字列のDate(yyyy-MM)をDateオブジェクトに変換
 */
export const parseStringDateToDate = (date: string) =>
  parse(date, 'yyyy-MM', new Date())

/**
 * 文字列のDate(yyyy-MM-dd)をDateオブジェクトに変換
 */
export const parseStringDateDayToDate = (date: string) =>
  parse(date, 'yyyy-MM-dd', new Date())

/**
 *  Dateオブジェクトを「HH:MM」形式の文字列に変換
 * @param date Dateオブジェクト
 * @returns 「HH:MM」形式の文字列
 */
export const parseTimeToString = (date: Date) => format(date, 'HH:mm')

/**
 *  Dateオブジェクトを「yyyyMMddHH」形式の文字列に変換
 */
export const parseYearDateHourToString = (date: Date) =>
  format(date, 'yyyyMMddHH')
