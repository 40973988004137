<script setup lang="ts">
import { defineEmits, defineProps, reactive, withDefaults } from 'vue'
import Input from '@/vueComponents/photographer/atoms/Input/index.vue'
import { IInputProps } from '@/vueComponents/photographer/atoms/Input'
import InputError from '@/vueComponents/photographer/atoms/InputError/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import { validatePassword } from '@/utils/functions/validation'
import { ValidationCode } from '@/utils/constants/enums/validation'
import { convertErrorMsgPassword } from '@/utils/functions/errorMsg'
import { ERRORMSG } from '@/utils/constants/messages/photographer/errorMsg'
import { IPasswordInputProps, IPasswordInputStates } from '.'

interface IProps {
  password?: IPasswordInputProps['password']
  withLabel?: IPasswordInputProps['withLabel']
  withHelp?: IPasswordInputProps['withHelp']
  withErrorMessages?: IPasswordInputProps['withErrorMessages']
}

interface IInputEmits {
  (e: 'update:password', value: IPasswordInputProps['password']): void
  (
    e: 'update:validation-code',
    value: IPasswordInputStates['validationCode']
  ): void
  (e: 'update:disable', value: boolean): void
}

const props = withDefaults(defineProps<IProps>(), {
  password: '',
  withLabel: true,
  withHelp: true,
  withErrorMessages: true,
})
const states = reactive<IPasswordInputStates>({
  validationCode: ValidationCode.EMPTY,
  value: '',
  errorMsg: ERRORMSG.EMPTY,
})
const emits = defineEmits<IInputEmits>()

const handleUpdate = (value: IInputProps['value']) => {
  const password = value as string
  emits('update:password', password)
  states.value = password
  states.validationCode = validatePassword(states.value)
  emits('update:validation-code', states.validationCode)
}

const handleValidate = () => {
  states.errorMsg = convertErrorMsgPassword(states.validationCode)
  emits('update:disable', !!states.errorMsg)
}
</script>

<template>
  <Typography v-if="props.withLabel">パスワード</Typography>
  <Input
    name="password"
    type="password"
    :value="props.password"
    placeholder="パスワード"
    :is-shadow="true"
    @update:value="handleUpdate"
    @blur="handleValidate"
  ></Input>
  <InputError v-if="props.withErrorMessages">{{ states.errorMsg }}</InputError>
  <Typography v-if="props.withHelp"
    >半角英字のみ、8文字以上〜20文字以内</Typography
  >
</template>
