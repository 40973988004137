import { reactive, computed } from 'vue'
import { Computable } from '@/types/utils'
import { ILinkProps } from '@/vueComponents/photographer/atoms/Link/index'

type HeaderButton =
  | 'header-bell'
  | 'header-camera'
  | 'header-logout'
  | 'header-schedule'
  | 'header-file-alt'
  | 'header-account'

export type HeaderButtonProps = {
  buttonType: HeaderButton
}

type HeaderButtonStates = {
  isHover: boolean
  readonly hoverType: ILinkProps['hoverType']
  buttonLabel: string
}

export const useHeaderButtonStates = (
  props: HeaderButtonProps
): HeaderButtonStates =>
  reactive<Computable<HeaderButtonStates>>({
    isHover: false,
    hoverType: 'header-icon',
    buttonLabel: computed(() => {
      switch (props.buttonType) {
        case 'header-bell':
          return '通知'
        case 'header-camera':
          return 'イベント'
        case 'header-schedule':
          return 'スケジュール'
        case 'header-file-alt':
          return '請求書'
        case 'header-account':
          return 'アカウント'
        case 'header-logout':
          return 'ログアウト'
      }
    }),
  })

type HeaderButtonActions = {
  hoverLink: () => void
  leaveLink: () => void
}

export const useHeaderButtonActions = (
  states: HeaderButtonStates
): HeaderButtonActions => {
  return {
    hoverLink() {
      if (states.isHover) return
      states.isHover = true
    },
    leaveLink() {
      states.isHover = false
    },
  }
}
