import { photographerConstants } from '@/utils/constants/photographerConstants'

export const MESSAGE = {
  TO_LOGIN: 'ログイン',
  TO_RESET_PASSWORD: 'パスワードを忘れた方はこちら',
  PASSWORD_RESETTING: `パスワードの再設定を行います。
    新しく設定するパスワードを入力してください。`,
  PASSWORD_UPDATING: `ご案内メールに記載されたパスワードは初期パスワードです。
    必ずご任意のパスワードに設定してからお使いください。`,
  PASSWORD_RESET: `パスワードの設定が完了しました。
    ${photographerConstants.AUTO_REDIRECT_SECONDS}秒後に、TOPページへ自動的に移動します。`,
  PHOTO_UPLOAD_REQUIRED:
    'すべてのフォルダに1枚以上、写真をアップロードしてください。',
  SENDING_BACK_FIX_REQUIRED: `修正がすべて完了したら「修正を完了した」にチェックを入れて、
      再検品依頼をして下さい。`,
  HAS_ERROR_PHOTOS: `フォルダ内の画像にエラーがあります。修正して上書き・削除してください。`,
} as const
