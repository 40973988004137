<script setup lang="ts">
import { defineEmits, defineProps, useSlots, withDefaults } from 'vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import { IButtonProps } from '@/vueComponents/photographer/atoms/Button'
import Form from '@/vueComponents/photographer/atoms/Form/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import CloseButton from '@/vueComponents/photographer/molecules/CloseButton/index.vue'
import { ModalProps, ModalEmitParams, useModalActions } from '.'

interface IProps {
  shown: ModalProps['shown']
  closeable?: ModalProps['closeable']
  submitDisabled?: ModalProps['submitDisabled']
  btnColor?: IButtonProps['color']
  enterDisabled?: ModalProps['enterDisabled']
  onSubmit?: ModalProps['onSubmit']
  onClose?: ModalProps['onSubmit']
  onBeforeCancel?: ModalProps['onBeforeCancel']
}

interface IEmits {
  (e: 'update:shown', shown: ModalEmitParams['update:shown']): void
}

const props = withDefaults(defineProps<IProps>(), {
  closeable: true,
  submitDisabled: false,
  btnColor: 'primary',
  enterDisabled: false,
  onSubmit: undefined,
  onClose: undefined,
  onBeforeCancel: undefined,
})
const emits = defineEmits<IEmits>()

const actions = useModalActions(props, emits)

const slots = useSlots()
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as position-mixins;
@use '@/styles/mixins/space' as space-mixins;

$corner-radius: 15px;
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(80, 80, 80, 0.5);
}
.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - space(4));
  max-width: 848px;
  max-height: calc(100% - space(4));
  border-radius: $corner-radius;
  background-color: colors.$background-color-modal;
}
.header {
  flex: 0;
  position: relative;
  padding: 0 space(6);
  min-height: 78px;
  background-color: colors.$background-color-modal-header;
  border-bottom: 1px solid colors.$background-color-modal-border;
  border-radius: $corner-radius $corner-radius 0 0;
  @include position-mixins.flex-horizontal;
  justify-content: space-between;
}
.content {
  flex: auto;
  @include space-mixins.margin-column(6, 6);
}
.footer {
  flex: 0;
  border-top: 1px solid colors.$background-color-modal-border;
  .buttons {
    @include space-mixins.vertical-margin(6, 6);
    @include position-mixins.flex-vertical(space(4));
    .button {
      width: 100%;
      max-width: 288px;
    }
  }
}
</style>

<style lang="scss" scoped>
.fade {
  &-enter-active {
    transition: all 0.2s ease-out;
  }

  &-leave-active {
    transition: all 0.2s ease-in;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
</style>

<template>
  <teleport to="body">
    <transition name="fade">
      <div v-if="props.shown" :class="css.mask" @click.self="actions.close">
        <div :class="css.container">
          <div :class="css.header">
            <div>
              <Typography type="h2-simple">
                <slot name="header" />
              </Typography>
            </div>
            <CloseButton v-if="props.closeable" @click="actions.close" />
          </div>
          <div :class="css.content">
            <Form v-if="!props.enterDisabled" @enter="props.onSubmit">
              <slot />
            </Form>
            <template v-else>
              <slot />
            </template>
          </div>
          <div
            v-if="slots.submitLabel || slots.cancelLabel"
            :class="css.footer"
          >
            <div :class="css.buttons">
              <div v-if="slots.submitLabel" :class="css.button">
                <Button
                  :on-click="actions.submit"
                  :disabled="props.submitDisabled"
                  :color="props.btnColor ? props.btnColor : 'primary'"
                  ><slot name="submitLabel"
                /></Button>
              </div>
              <div
                v-if="slots.cancelLabel && props.closeable"
                :class="css.button"
              >
                <Button color="ternary" :on-click="actions.onBeforeCancel"
                  ><slot name="cancelLabel"
                /></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>
