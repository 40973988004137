export enum ValidationCode {
  SUCCESS,
  EMPTY,
  PATTERN_MISMATCH,
  TOO_SHORT,
  TOO_LONG,
  NOT_THE_SAME,
  NOT_ACCEPTED,
  FILE_SIZE_TOO_LARGE,
  RESOLUTION_TOO_LOW,
  TOO_MANY,
  UNEXPECTED_VALUE,
  UNEXPECTED_ERROR,
}
