import { App, InjectionKey, Ref } from 'vue'

export class BaseStore {
  [prop: string]: Ref
}

type StoreKey = InjectionKey<BaseStore>
type StoreClass = { new (): BaseStore }
type StoresKeyValue = [StoreKey, StoreClass][]
type StoresMap = Map<StoreKey, StoreClass>

export class Stores {
  #storeMap: StoresMap

  constructor(storesKeyValue: StoresKeyValue) {
    this.#storeMap = new Map(storesKeyValue)
  }

  public install(app: App): void {
    for (const [injectionKey, Store] of this.#storeMap) {
      app.provide(injectionKey, new Store())
    }
  }
}
