import { Computable } from '@/types/utils'
import { computed, reactive, ref } from 'vue'
import { IconProps } from '../../atoms/Icon'

type AccordionStates = {
  isOpened: boolean
  iconType: IconProps['type']
}

export const useAccordionStates = (): AccordionStates => {
  const isOpenedRef = ref(true)
  return reactive<Computable<AccordionStates>>({
    isOpened: computed({
      get: () => isOpenedRef.value,
      set: (value: boolean) => (isOpenedRef.value = value),
    }),
    iconType: computed(() =>
      isOpenedRef.value ? 'arrow-medium-up' : 'arrow-medium-down'
    ),
  })
}

type AccordionActions = {
  toggle: () => void
}

export const useAccordionActions = (
  states: AccordionStates
): AccordionActions => ({
  toggle: () => (states.isOpened = !states.isOpened),
})
