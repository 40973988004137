import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { App } from 'vue'

/**
 * Bugsnagの設定を行うClass
 */
export class BugsnagPlugin {
  static #app: App

  public static install(app: App): void {
    this.#app = app
    this.#start()
  }

  /**
   * Bugsnagの有効化
   */
  static #start(): void {
    Bugsnag.start({
      apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginVue()],
      releaseStage: import.meta.env.VITE_RELEASE_STAGE || 'local',
    })
    const bugsnagVue = Bugsnag.getPlugin('vue')
    bugsnagVue?.install(this.#app)
  }
}
