<script setup lang="ts">
import { computed, defineProps, withDefaults } from 'vue'
import { ILinkProps } from '.'

interface IProps {
  type?: ILinkProps['type']
  location?: ILinkProps['location']
  href?: ILinkProps['href']
  targetBlank?: ILinkProps['targetBlank']
  onClick?: ILinkProps['onClick']
  display?: ILinkProps['display']
  hoverType?: ILinkProps['hoverType']
}

const props = withDefaults(defineProps<IProps>(), {
  type: 'body-read',
  location: undefined,
  href: '',
  targetBlank: false,
  onClick: () => null,
  display: 'inline',
  hoverType: undefined,
})

const href = computed(() => props.href || 'javascript:void(0)')
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/sizes' as sizes;
@use '@/styles/mixins/typography' as typography-mixins;
@use '@/styles/mixins/font' as font-mixins;
@use '@/styles/mixins/hover' as hover-mixins;
.text-decoration {
  text-decoration: none;
}
.link {
  cursor: pointer;
}
.display {
  &-block {
    display: block;
  }

  &-inline {
    display: inline;
  }

  &-inline-block {
    display: inline-block;
  }
}
.type {
  &-body-read {
    @include typography-mixins.typography-type-base(
      sizes.$font-size-sm,
      1.2,
      colors.$font-color-link
    );
    @include font-mixins.font-weight-medium;
    @include hover-mixins.hover-opacity;
  }
  &-body-read-disable {
    text-decoration: none;
    color: colors.$font-color-link-disabled;
    font-size: sizes.$font-size-sm;
    @include font-mixins.font-weight-medium;
    cursor: not-allowed;
  }
  &-body-read-normal {
    text-decoration: none;
    color: colors.$font-color-link;
    font-size: sizes.$font-size-md;
    @include font-mixins.font-weight-medium;
    @include hover-mixins.hover-opacity;
  }
  &-edit {
    text-decoration: underline;
    color: colors.$font-color-link;
    font-size: sizes.$font-size-md;
    @include hover-mixins.hover-opacity;
  }
  &-footer {
    text-decoration: none;
    color: colors.$font-color-footer;
    font-size: sizes.$font-size-footer;
  }
}
.hover {
  &-header-icon {
    @include hover-mixins.hover-header-icon;
    @include hover-mixins.hover-opacity(1);
  }
}
</style>

<template>
  <component
    :is="props.location ? 'router-link' : 'a'"
    :class="css['text-decoration']"
    :to="props.location"
    :href="href"
    :target="props.targetBlank ? '_blank' : null"
    v-on="props.onClick ? { click: props.onClick } : {}"
  >
    <div
      :class="[
        css.link,
        css[`display-${props.display}`],
        css[`type-${props.type}`],
        props.hoverType ? css[`hover-${props.hoverType}`] : '',
      ]"
    >
      <slot />
    </div>
  </component>
</template>
