import axiosBase, { AxiosInstance, AxiosError } from 'axios'
import { App } from 'vue'
import { pluginKeys } from '@/utils/constants/pluginKeys'
import { API_STATUS } from '@/utils/constants/enums/apiStatus'
import { UnauthorizedError } from '@/utils/modules/exceptions/UnauthorizedError'
import { UnprocessableEntityError } from '@/utils/modules/exceptions/UnprocessableEntityError'
import { InternalServerError } from '@/utils/modules/exceptions/InternalServerError'
import { ServiceUnavailableError } from '@/utils/modules/exceptions/ServiceUnavailableError'
import { NotFoundError } from '@/utils/modules/exceptions/NotFoundError'
import { NetworkError } from '@/utils/modules/exceptions/NetworkError'
import { FailedDependencyError } from '@/utils/modules/exceptions/FailedDependencyError'
import { UnexpectedServerError } from '@/utils/modules/exceptions/UnexpectedServerError'

export interface IAxiosConfigData {
  [key: string]: string | number | boolean
}

const createAxios = (): AxiosInstance => {
  // 初期設定
  const apiHost = window.location.host
  return axiosBase.create({
    baseURL: location.protocol + '//' + apiHost,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
}

const replacePathParamterKey = (axios: AxiosInstance): void => {
  // パスパラメータの :key に値をセットする
  axios.interceptors.request.use((config) => {
    if (!config.url || config.url.indexOf(':') === -1) {
      return config
    }
    Object.entries((config.data as IAxiosConfigData) || {}).forEach(
      ([key, val]) => {
        const value: string = typeof val === 'string' ? val : String(val)
        config.url = config.url?.replace(`:${key}`, value) ?? ''
      }
    )
    return config
  })
}
const defineErrorHandler = (axios: AxiosInstance): void => {
  axios.interceptors.response.use(undefined, (error: AxiosError) => {
    const { response } = error
    switch (response?.status) {
      case API_STATUS.UNAUTHORIZED:
        return Promise.reject(new UnauthorizedError())
      case API_STATUS.NOT_FOUND:
        return Promise.reject(new NotFoundError())
      case API_STATUS.UNPROCESSABLE_ENTITY:
        return Promise.reject(new UnprocessableEntityError(response))
      case API_STATUS.FAILED_DEPENDENCY:
        return Promise.reject(new FailedDependencyError())
      case API_STATUS.INTERNAL_SERVER_ERROR:
        return Promise.reject(new InternalServerError())
      case API_STATUS.SERVICE_UNAVAILABLE:
        return Promise.reject(new ServiceUnavailableError())
      case undefined:
        return Promise.reject(new NetworkError())
      default:
        return Promise.reject(new UnexpectedServerError(error))
    }
  })
}

/**
 * axiosをカスタマイズするClass
 */
export class AxiosPlugin {
  public static install(app: App): void {
    const axios = createAxios()
    replacePathParamterKey(axios)
    defineErrorHandler(axios)
    app.provide(pluginKeys.AXIOS, axios)
  }
}
