import { CreatingEntity } from '@/entities/global/CreatingEntity'
import { Emits } from '@/types/utils'

export type FormEmitParams = {
  enter: void
}

type FormActions = {
  onEnter: (event: KeyboardEvent) => void
}

const IME_ENTER_KEY_CODE = 229

export const useFormActions = (emits: Emits<FormEmitParams>): FormActions => {
  const creatingEntity = new CreatingEntity()
  return {
    onEnter(event) {
      if (creatingEntity.isCreating) return
      if (event.keyCode === IME_ENTER_KEY_CODE) return
      emits('enter')
    },
  }
}
