import { BaseEntity } from '@/entities/BaseEntity'
import {
  CreatingStore,
  creatingStoreKey,
} from '@/vue/stores/global/CreatingStore'

export class CreatingEntity extends BaseEntity<CreatingStore> {
  constructor() {
    super(creatingStoreKey)
  }

  public get isCreating(): boolean {
    return this.store.creatingSymbols.value.size > 0
  }

  public addCreateSymbol(createSymbol: symbol) {
    this.store.creatingSymbols.value.add(createSymbol)
  }

  public deleteCreateSymbol(createSymbol: symbol) {
    this.store.creatingSymbols.value.delete(createSymbol)
  }

  /**
   * 現在実行中の非同期処理に同一のシンボルを使用しているものがあるかを判定
   * @param createSymbol 非同期処理用シンボル
   * @returns true:同一シンボルあり false:同一シンボルなし
   */
  public hasCreateSymbol(createSymbol: symbol): boolean {
    return this.store.creatingSymbols.value.has(createSymbol)
  }
}
