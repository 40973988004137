import { ValueOf } from '@/types/utils'
import { ERRORMSG } from '@/utils/constants/messages/photographer/errorMsg'
import { ValidationCode } from '@/utils/constants/enums/validation'
import { PhotoUploadStatus } from '@/utils/constants/enums/photographer/photo'

export type ErrorMsg = ValueOf<typeof ERRORMSG>

export const convertErrorMsgMail = (code: ValidationCode): ErrorMsg => {
  switch (code) {
    case ValidationCode.EMPTY:
      return ERRORMSG.MAIL_EMPTY
    case ValidationCode.TOO_LONG:
      return ERRORMSG.MAIL_TOO_LONG
    case ValidationCode.PATTERN_MISMATCH:
      return ERRORMSG.MAIL_PATTERN_MISMATCH
    default:
      return ERRORMSG.EMPTY
  }
}

export const convertErrorMsgPassword = (code: ValidationCode): ErrorMsg => {
  switch (code) {
    case ValidationCode.EMPTY:
      return ERRORMSG.PASSWORD_EMPTY
    case ValidationCode.TOO_LONG:
      return ERRORMSG.PASSWORD_TOO_LONG
    case ValidationCode.TOO_SHORT:
      return ERRORMSG.PASSWORD_TOO_SHORT
    case ValidationCode.PATTERN_MISMATCH:
      return ERRORMSG.PASSWORD_PATTERN_MISMATCH
    default:
      return ERRORMSG.EMPTY
  }
}

export const convertErrorMsgPhotographerLoginPassword = (
  code: ValidationCode
): ErrorMsg => {
  switch (code) {
    case ValidationCode.EMPTY:
      return ERRORMSG.PASSWORD_EMPTY
    case ValidationCode.TOO_LONG:
      return ERRORMSG.PHOTOGRAPHER_LOGIN_PASSWORD_TOO_LONG
    case ValidationCode.TOO_SHORT:
      return ERRORMSG.PASSWORD_TOO_SHORT
    case ValidationCode.PATTERN_MISMATCH:
      return ERRORMSG.PASSWORD_PATTERN_MISMATCH
    default:
      return ERRORMSG.EMPTY
  }
}
export const convertErrorMsgRequesterLoginPassword = (
  code: ValidationCode
): ErrorMsg => {
  switch (code) {
    case ValidationCode.EMPTY:
      return ERRORMSG.PASSWORD_EMPTY
    case ValidationCode.TOO_LONG:
      return ERRORMSG.REQUESTER_LOGIN_PASSWORD_TOO_LONG
    case ValidationCode.TOO_SHORT:
      return ERRORMSG.PASSWORD_TOO_SHORT
    case ValidationCode.PATTERN_MISMATCH:
      return ERRORMSG.PASSWORD_PATTERN_MISMATCH
    default:
      return ERRORMSG.EMPTY
  }
}

export const convertErrorMsgPasswordConfirm = (
  code: ValidationCode
): ErrorMsg => {
  if (code === ValidationCode.NOT_THE_SAME) {
    return ERRORMSG.PASSWORD_COMFIRMPASSWORD_MISMATCH
  }
  return convertErrorMsgPassword(code)
}

export const convertErrorMsgLoginId = (code: ValidationCode): ErrorMsg => {
  switch (code) {
    case ValidationCode.EMPTY:
      return ERRORMSG.LOGINID_EMPTY
    case ValidationCode.TOO_LONG:
      return ERRORMSG.LOGINID_TOO_LONG
    case ValidationCode.PATTERN_MISMATCH:
      return ERRORMSG.LOGINID_PATTERN_MISMATCH
    default:
      return ERRORMSG.EMPTY
  }
}

/**
 * 写真アップロードステータスをエラーメッセージに変換
 * @param status 写真アップロードステータス
 * @returns エラーメッセージ(正常の場合は空文字)
 */
export const convertErrorMsgPhoto = (status: PhotoUploadStatus): ErrorMsg => {
  switch (status) {
    case PhotoUploadStatus.COMPLETE:
    case PhotoUploadStatus.VALIDATING:
    case PhotoUploadStatus.TRIMMING:
    case PhotoUploadStatus.CREATING_THUMBNAIL:
    case PhotoUploadStatus.ERROR_TIMEOUT:
      return ERRORMSG.EMPTY
    case PhotoUploadStatus.ERROR_INVALID_IMAGE_FORMAT:
    case PhotoUploadStatus.ERROR_INVALID_FILE_SIZE:
      return ERRORMSG.BROKEN_IMAGE
    case PhotoUploadStatus.ERROR_INVALID_IMAGE_RESOLUTION:
      return ERRORMSG.IMAGE_RESOLUTION
    default:
      return ERRORMSG.UPLOAD_FAILED
  }
}
