import { Emits, Promisable } from '@/types/utils'
import { IButtonProps } from '@/vueComponents/photographer/atoms/Button/index'

export type ModalProps = {
  shown: boolean
  closeable?: boolean
  submitDisabled?: boolean
  btnColor?: IButtonProps['color']
  enterDisabled?: boolean
  onSubmit?: () => Promisable<void>
  onClose?: () => Promisable<void>
  onBeforeCancel?: () => Promisable<void>
}

export type ModalEmitParams = {
  'update:shown': ModalProps['shown']
}

type ModalActions = {
  submit: () => Promisable<void>
  close: () => Promisable<void>
  onBeforeCancel: () => Promisable<void>
}

export const useModalActions = (
  props: ModalProps,
  emits: Emits<ModalEmitParams>
): ModalActions => {
  return {
    async submit() {
      if (props.onSubmit) await props.onSubmit()
    },
    async close() {
      if (!props.closeable) return
      if (props.onClose) await props.onClose()
      emits('update:shown', false)
    },
    async onBeforeCancel() {
      if (props.onBeforeCancel) await props.onBeforeCancel()
      emits('update:shown', false)
    },
  }
}
